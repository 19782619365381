<template lang="pug">
  div
    div.text-center(v-if="loading")
      v-progress-circular( indeterminate color="primary" )
    castboard(v-else)
</template>

<script>
import Castboard from '@/components/Forecasts/Castboard'
import { mapActions } from 'vuex'

export default {
  components: {
      Castboard
  },
  data() {
    return {
      loading:true,
      idCastzone: null,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if(to.hash) {
          this.idCastzone =  to.hash.replace('#','')
        }
      }
    },
    idCastzone: {
      immediate: true,
      handler() {
        this.fetchData()
      }
    },
  },
  mounted() {
  },
  methods: {
    ...mapActions('forecast', ['fetchCastzoneContext','resetCastpoints']),
    async fetchData() {
      if(this.idCastzone) {
        await this.resetCastpoints()

        this.gLoading(this.$t('forecasts.loading.context'))
        await this.fetchCastzoneContext(this.idCastzone)
        this.gLoadingEnd()

        this.loading=false
      }
    }
  }
}
</script>

<style scoped>
</style>
